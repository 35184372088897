export default {
    dbName: 'ydfda_test',
    dataTables:[
      "site_visit",
      "net_set",
      "catch",
      "tally",
      "lab_fish",
      "lab_fish_sample",],
    refTables:["site",
      "tributary",
        "species",
        "size_class",
        "sample_type",
        "age",
        "anal_fin",
        "dorsal_fin",
        "gear",
        "adipose_fin",
        "sample_method",
        "ref_observer",
  ],
  views: ["sample_query"],
    tableFields:{
        tally: [
            {key:'_id', label: "", type:null},
            {key:'species', label:'Species', type:'select', optionstable:"species"},
            {key:'age', label:'Age',type:'select', optionstable:'age'},
            {key:'size_class', label: 'Size Class', type:'select', optionstable:'size_class'},
            {key:'tally', label: 'Count', type:'number'},
            {key:'comment_field',label: 'comment', type:'text'},
            {key:'global_id',label: "", type:null, keyfield: true},
            {key:'parent_id',label: "", type:null},
            {key:'set_num', label: "", type:'number'}
        ],
        catch: [
          {key:'_id', label: "",type:null},
          {key:'field_species', label:'Species', type:'select', optionstable:"species"}, 
          {key:'age', label:'Age',type:'select', optionstable:'age'},
          {key:'length', label: 'Length', type:'number'},
          {key:'mass_g',label: 'Mass(g)', type:'number'},
          {key:'sin', label:'SIN',type:'barcode'},
          {key:'comment_field',label: 'comment', type:'text'},
          {key:'sample_type',label: 'Sample', type:'select', optionstable: 'sample_type'},
          {key:'size_class', label: 'Size Class', type: 'select', optionstable:'size_class'},
          {key:'global_id',label: "", type:null, keyfield: true},
          {key:'parent_id',label: "", type:null},
          {key:'set_num', label: "", type:'number'}
        ],
        site_visit: [
          {key:'station', label: "station",type:'text',optionstable:null},
          {key:'visit_date', label: "visit_date",type:'text',optionstable:null},
          {key:'investigators', label: "investigators",type:'text',optionstable:null},
          {key:'temperature_c', label: "temperature_c",type:'numeric',optionstable:null},
          {key:'gear', label: "gear",type:'text',optionstable:null}
        ],
        net_set:[
          {key:'set_num', label:'set_num', type:'numeric',optionstable:null},
          {key:'depth_start', label:'depth (m)', type:'numeric',optionstable:null},
          {key:'depth_stop', label:'depth (m)', type:'numeric',optionstable:null},
          {key:'flow_start', label:'start flow', type:'numeric',optionstable:null},
          {key:'flow_stop', label:'stop flow', type:'numeric',optionstable:null},
          {key:'gear', label:'gear', type:'text',optionstable:'gear'},
          {key:'start_longitude', label:'start longitude', type:'numeric',optionstable:null},
          {key:'start_latitude', label:'start latitude', type:'numeric',optionstable:null},
          {key:'stop_longitude', label:'stop longitude', type:'numeric',optionstable:null},
          {key:'stop_latitude', label:'stop latitude', type:'numeric',optionstable:null},
          {key:'comment', label:'comment', type:'text',optionstable:null}
  
        ],
        lab_fish: [
          {key:'field_species', label: "field species",type:"select",optionstable:"species"},
          {key:'official_species', label: "official species",type:"select",optionstable:"species",},
          {key:'age', label: "age",type:"select",optionstable:"age"},
          {key:'length', label: "length",type:"numeric",optionstable:null},
          {key:'mass_g', label: "mass(g)",type:"numeric",optionstable:null},
          {key:'dorsal_fin', label: "dorsal fin",type:"select",optionstable:"dorsal_fin"},
          {key:'anal_fin', label: "anal fin",type:"select",optionstable:"anal_fin"},
          {key:'adipose_fin', label: "adipose fin",type:"select",optionstable:"adipose_fin"},
          {key:'brachiostegols', label: "brachiostegols",type:"numeric",optionstable:null},
          {key:'sin', label: "sin",type:"text",optionstable:null},
          {key:'comment_post', label: "office comment",type:"text",optionstable:null}, 
          {key:'comment_field', label: "field comment",type:"text",optionstable:null},
          ],
          lab_fish_sample: [
            {key:'sin', label: "SIN ",type:"text",optionstable:null},
            {key:'sample_type',label:"sample_type",type:"select",optionstable:"sample_type"},
            {key:'sample_method',label:"sample_method",type:"select",optionstable:"sample_method"},
            {key:'sample_card',label:"sample card",type:"text",optionstable:null},
            {key:'sample_card_location',label:"sample_card_location",type:'numeric',optionstable:null},  
          ],
          sample_query: [
            {key:'station', title: "station"},
          {key:'visit_date', title: "visit date"},
          {key:'investigators', title: "investigators"},
          {key:'temperature_c', title: "temperature c"},
          {key:'gear', title:"gear"},
            {key:'net', title:"net",},
            {key:'field_species', title: "field species",},
            {key:'age', title: "age"},
            {key:'length', title: "length"},
            {key:'mass_g', title:"mass(g)"},
            {key:'sin', title: "sin"},
            {key:'tally', title:"tally"}
          ]
    },
tableKeys: {
  site_visit:['station','tributary','visit_date'],
  net_set:['station','visit_date', 'set_num'],
  catch:['station','visit_date', 'set_num','global_id'],
  tally:['station','visit_date', 'set_num','global_id'],
  lab_fish:['station','visit_date','global_id'],
  lab_fish_sample:[]
},

}