import { createApp } from 'vue'
import App from './App.vue'
import { loadFonts } from './plugins/webfontloader'
//cookies for authentication
import Cookies from "js-cookie";
import router from './router'
// Vuetify
import vuetify from "./plugins/vuetify"
//vuex
import store from './store'
// vue-papa-parse
import VuePapaParse from "vue-papa-parse";


loadFonts()

createApp(App).
  use(VuePapaParse).
  use(vuetify).
  use(router).
  use(store).
  use(Cookies).
  mount('#app')
