import { createMemoryHistory, createRouter } from 'vue-router'

import HomeView from '../views/Home.vue'
import FishLab from '../views/FishLab.vue'
import FishData from '../views/Fish.vue'
import SummaryData from '../views/SummaryData.vue'
import Login from '../components/Login.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
 {
    path: '/fishlab',
    name: 'FishLab',
    component: FishLab
  },
  {
    path: '/fish',
    name: 'Fish',
    component: FishData
  },
  {
    path: "/login/",
    name: "login",
    component: Login,
  },
  {
    path: '/summary_data',
    name: 'SummaryData',
    component: SummaryData
  }
]
const router = createRouter({
  history: createMemoryHistory(),
  routes,
  base: process.env.BASE_URL,
})


export default router
