<template>
  <Scatter :data="plotData" :options="options" />
</template>

<script>
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LinearScale
} from 'chart.js'

import { Scatter} from 'vue-chartjs'
ChartJS.register(LinearScale, PointElement, Tooltip, LineElement, Legend)
export default {
  name: "LengthMassPlot",
  components:{ Scatter },
  props:{
    plotData: {
      type:Object,
      default: () => ({
        datasets: [
        {
          borderColor: 'red',
          showLine: true,
          pointRadius: 0,
          label: 'Ideal Model',
          backgroundColor: 'red',
          data: [
            {
              x: 1,
              y: 1,
            },
            {
              x: 1000,
              y: 1000,
            }
          ]
        },
        {
          showLine: false,
          pointRadius: 1,
          label: 'Data Model',
          backgroundColor: 'blue',
          data: [
            {
              x: 0,
              y: 0,
            },
            {
              x: 1000,
              y: 1000,
            }
          ]
        },
        ],
      })
    }
  },
  data() {
    return {
     
    }
  },
  computed: {
    options () {
      return {
        scales: {
          y: {
            title: {
              display: true,
              text: "Length (mm)"
            }
          },
          x: {
            title: {
              display: true,
              text: "Mass (g)"
            }
          }
       },
        responsive: true,
        maintainAspectRatios: false,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: "Fish Length:Mass",
            align: "center"
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: function(context) {
                return context.dataset.keys[context.dataIndex]
              }
            }
          }
        }
      }
    }
  },
}

</script>
<style>

</style>
