<template>
<v-container id="data_table" >

  <div class="mb-16" v-if="showAdd">
    
    <!-- <v-row class="row flex-nowrap" no-gutters center> -->
    <v-row v-if="singleItemHeader.length > 5" class="row flex" no-gutters center>
      <!-- <v-btn @click="addRecord">Add
      <v-tooltip
              activator="parent"
              location="bottom"
            >{{ addRecordText }}</v-tooltip>
    </v-btn> -->
      <div v-for="(header, index) in singleItemHeader" class="row table_cell" v-bind:key="`${header.key}-${index}`">   
        <InlineTableEditCell v-if="header.text !==''" class= "input" :header="header" :value="newRecord" /> 
      </div>
    <v-btn @click="addRecord">Add
      <v-tooltip
              activator="parent"
              location="bottom"
            >{{ addRecordText }}</v-tooltip>
    </v-btn>
  </v-row>
   <v-row v-else class="row flex-nowrap" no-gutters center> 
      <v-btn @click="addRecord">Add
      <v-tooltip
              activator="parent"
              location="bottom"
            >{{ addRecordText }}</v-tooltip>
    </v-btn>
      <div v-for="(header, index) in singleItemHeader" class="row table_cell" v-bind:key="`${header.key}-${index}`">   
        <InlineTableEditCell v-if="header.text !==''" class= "input" :header="header" :value="newRecord" /> 
      </div>
    <v-btn @click="addRecord">Add
      <v-tooltip
              activator="parent"
              location="bottom"
            >{{ addRecordText }}</v-tooltip>
    </v-btn>
  </v-row>

  </div >

    <v-data-table 
    :headers="headers"
     :items="tableItems"
     items-per-page="100">
    <template v-slot:item="i">
          <tr :class="{ selected: i.item.selected }">
            <td v-for="header in headers"  v-bind:key="header.key">
              <InlineTableEditCell v-if="header.title !==''" class= "input" :header="header" :value="i.item" v-on="handler" @click="handleRecordSelected(i.item)" /> 
              <!-- {{ i.item[header.key] }} -->
            </td>
            <RecordEditMenu class="d-inline-flex" :value="i.item" v-on="handler" :btns="rowButtons"></RecordEditMenu>    
          </tr>
        </template>

</v-data-table>
 

    </v-container>
</template>

  

<script>
import RecordEditMenu from '@/components/RecordEditDialog.vue'
import support from '../plugins/support'
//import HorizontalScroll from 'vue-horizontal-scroll'
//import 'vue-horizontal-scroll/dist/vue-horizontal-scroll.css'
import InlineTableEditCell from './InlineTableEditCell.vue';
export default {
  name: "EditableTable",
  components: {
    InlineTableEditCell,
    RecordEditMenu
  },
  selectedCell: null,
  props: {
      value: Array, //2 way bound records
      fields: Array,
      tableName:String,
      singleSelect:{
        type:Boolean,
        default:true
      },
      reverse:{
        type:Boolean,
        default:true
      },
      sortField: String,
      showAdd: {
        type:Boolean,
        default:false
      },
      addFields:Array,
      rowButtons:{
        type: Array,
        default: ()=>  [
        { title: 'Delete', icon: 'mdi-delete', signal: 'delete_record' }
      ]
  
      },
      isSummary: Boolean

  },
  data() {
    return {
      headers:[],
      tableItems:[],
      newRecord:{},
      singleItemHeader:[],
      addRecordText: "add a " + this.tableName + " record",
      showCopyDialog:false,
      watcher:true,
      copyKey:this.fields.filter(function(el){
      return el['keyField'] === true})[0],
      someitem:{selected:true},
      handler:{
        record_selected:this.handleItemSelected,
        delete_record:this.deleteItem,
        copy_record:this.copyRecord,
        //input:this.submit,
        item_changed:this.submitItem
      }
      };
  },
  mounted(){
    this.tableItems = this.getTableItems(this.value).map((el) => ({...el, selected:false}))
     this.headers = this.fields.map(item => ({...item, title: item.label, key: item.key,
         align:'left'}))
     //this.headers.push({title:'Select', key:'selected'})
     this.singleItemHeader = this.addFields ? this.addFields.map(item => ({...item, text: item.label, value: item.key,
      align:'center'})) : this.headers 
  },
  created(){
   this.$watch(
          'value',() => {
              this.tableItems = this.getTableItems(this.value)
              this.newRecord = this.makeNewRecord()
  
            },
          {deep: true}
    );
    if (this.showAdd){
      this.newRecord = this.makeNewRecord()
    }
  },
  computed:{
   keys(){
    return ['global_id']
   },

  },
  methods: {
    addRecord() {
      this.$emit('add_record',{tableName:this.tableName,record:this.newRecord})
      //this.newRecord = this.makeNewRecord() //trigger this on items change
    },
    makeNewRecord() {
      let item = {}
      for (let field in this.fields){
        item[this.fields[field].key]= this.fields[field].default ? this.fields[field].default : ''
      }
      return item
    },
    getTableItems(value){
      let sortField = this.sortField;
      
      if (value !== undefined){
        if (!this.reverse){
                return sortField ?
              this.value.map((item) => ({...item, delete:false})).sort(
                (a, b) => a[sortField] - b[sortField])
              : this.value.map((item) => ({...item, delete:false}));
        } else {
          return sortField ?
              this.value.map((item) => ({...item, delete:false})).sort(
                (a, b) => new Date(b[sortField]) - new Date(a[sortField]))
              : this.value.map((item) => ({...item, delete:false}));
        }
      }else{
        return []
      }
    },
    handleItemSelected(item){
      let i = 0;
      let found = false;
      while ((!found) & (i < this.tableItems.length)) {
        found = support.matchItem(item, this.tableItems[i], this.keys)
        if (found) {
          this.handleRecordSelected(i)
        }
        i++
      }
    },
    handleRecordSelected(index){
      console.log("item selected")
      if (this.singleSelect){
       
          this.tableItems = this.getTableItems(this.value).map((el) => ({...el, selected:false}))
      }
      this.tableItems[index].selected !== this.tableItems[index].selected;
      this.$store.commit('updateAttribute',{attribute:`current_${this.tableName}`, value:this.tableItems[index]},)
    },
    refTables(tablename){
      return this.$store.getters['refTables'](tablename)
    },
    submit(){
      this.$emit('submit',{payload:this.tableItems,tableName:this.tableName})
    },
    submitItem(item){
      if(this.isSummaryTable){

        let oldItem = support.findItem(item,this.values,this.keys)
      console.log(oldItem)
      console.log(item)
      } else {
        this.$emit('submitItem',{record:item,tableName:this.tableName})
      }
    },
    deleteItem(item) {

      this.$emit('delete',{table:this.tableName,record:item});
    },


    isFirstRecord(index){
      return index == 0;
    },
   
    buttonAction(btn,item){
      let info = {tableName: this.tableName, item:item}
      this.$emit(btn.signal,info)
    },
    copyRecord(){

    }
  }
    };
    </script>
    <style scoped>
   
#data_table, #data_datas{ padding-left: 1rem; padding-right: 1rem }
#data_table{padding-left:1rem; padding-right:1rem;}
#data_table .column{ padding:3px;}
#data_table .row{ display:flex; margin-left:0px; margin-right:0px; align-items:baseline; justify-content: flex-start}
#data_table .selected{ margin-left:0px; margin-right:0px; background-color: lightblue;}
#data_table .top_titles div{ text-align:center; align-items:center;
  overflow-x: auto;color: var(--green); text-transform: uppercase; font-weight: 500; font-size: 75%;}
#data_table div.top_titles{height:50px;
  overflow-x: auto; text-align:center; color: var(--green); text-transform: uppercase; font-weight: 500; font-size: 75%;}
#data_table div.table_cell .input{ flex:none; padding:3px; margin:3px;
    border: 0px; background-color: rgba(0,0,0,.05); color: var(--army); font-weight: 500;
  height:50px;}
  #single-entry{ padding:10px; margin:60px}



 

  

       </style>

