<!-- App.vue -->
<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-app-bar-title>Yukon Fish Data v{{ $store.getters.attribute('version') }}</v-app-bar-title>
      <v-spacer></v-spacer>
      <v-app-bar-title app>
        <v-tooltip bottom close-delay="2000">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon to="/" v-on="on" v-bind="attrs">
              <v-icon>mdi-home-circle</v-icon>
            </v-btn>
          </template>
          <span> Return home</span>
        </v-tooltip>
        <!-- <v-tooltip bottom close-delay="2000">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs" @click="save" :disabled="access_level < 2">
              <v-icon>mdi-content-save-all</v-icon>
            </v-btn>
          </template>
          <span> Save and submit data</span>
        </v-tooltip> -->
        <v-tooltip bottom close-delay="2000">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              to="/fishlab"
              v-on="on"
              v-bind="attrs"
               :disabled="access_level < 2"
            >
              <v-icon>mdi-test-tube</v-icon>
            </v-btn>
          </template>
          <span v-if="access_level > 1"> Open Fish Lab</span>
          <span v-else> You do not have access to this feature.</span>
        </v-tooltip>
        <v-tooltip bottom close-delay="2000">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              to="/fish"
              v-on="on"
              v-bind="attrs"
              :disabled="access_level < 2"
            >
              <v-icon>mdi-fish</v-icon>
            </v-btn>
          </template>
          <span v-if="access_level > 1"> Open Fish Data</span>
          <span v-else> You do not have access to this feature.</span>
        </v-tooltip>
         <v-tooltip bottom close-delay="2000">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              to="/summary_data"
              v-on="on"
              v-bind="attrs"
              :disabled="access_level < 1"
            >
              <v-icon>mdi-table</v-icon>
            </v-btn>
          </template>
          <span v-if="access_level > 0"> View submitted data</span>
          <span v-else> You do not have access to this feature.</span>
        </v-tooltip>
      </v-app-bar-title>
    </v-app-bar>

    <v-main>
      <v-dialog v-model="showLogin" >
      <Login v-on="handler"></Login>
    </v-dialog>
      <router-view />
      <v-snackbar
        v-model="submittedSnackbar"
        :timeout="submittedTimeout"
        id="records-submitted"
        color="getColor"
        variant="tonal"
      >
        <div class="text pb-2">{{ $store.getters.attribute('submitMessage') }}</div>
      </v-snackbar>     
    </v-main>

    <v-footer color="primary" app fixed dark v-if="loggedIn">
      <p class="ma-0 pa-0">
        Logged in as {{ username }} 
      </p>
      <v-spacer />
      <v-btn color="success" @click="logout">Logout</v-btn>
    </v-footer>
    <v-footer class="ma-0 pa-0" v-else color="warning" app fixed dark>
      <p class="ma-0 pa-0">
        Not logged in. 
      </p>
      <v-spacer />
      <v-btn color="secondary" @click="loginRedirect($router.currentRoute.path)"
        >Login</v-btn
      >
    </v-footer>
  </v-app>
</template>

<script>
import support from './plugins/support.js'
import Login from '@/components/Login.vue';
export default {
  name: 'App',

  data: () => ({
    showLogin: false,
    submittedTimeout:6000,
    //submittedSnackbar:false
  }),
  components: {
    Login
  },
  async mounted () {
    this.$watch(
      "loggedIn",
      () => {
       if(this.loggedIn) {
        this.showLogin=false;
       }
      },
      { deep: true }
    );
    this.$watch(
      "access_level",
      () => {
        if ((this.access_level === 0) || (this.access_level === '')) {
          this.loginRedirect();
        }
      },
      { deep: true }
    );
    this.$store.dispatch("checkAuthentication");
    this.initializeData();
    this.loadData();
  },
  computed:{
    username () {
      return this.$store.getters.attribute('username');
    },
    loggedIn () {
      return this.$store.getters.attribute("loggedIn")
    },
    access_level() {
      return this.$store.getters.attribute("accessLevel");
    },
    submittedSnackbar: {
      get(){
        if (this.$store.getters.attribute('submitMessage').length > 0) {
          return true;
        }
        return false;
      },
      set(value){
        if (!value) {
          this.$store.state.submitMessage = ''
        }
      }
    }
  },
  methods: {
    handleLoginClick() {
      if (this.loggedIn) {
        this.$store.dispatch('logout')
      } else{
        this.showLogin=true
      }
    },
    loginRedirect: function (path) {
      this.$router.push({
        name: "login",
        query: { redirect: path },
      });
    },
    logout: function () {
      this.$store.dispatch("logout");
    },
    initializeData() {
      support.initializeReferenceTables()
      support.initializeDataTables()
      support.initializeViews()
    },
    loadData(){
      support.populateReferenceTables()
      //support.populateDataTables()
      support.populateViews()
    }
  }
};
</script>
