<template>
  <div class="text-center">
    <v-menu 
      top
      offset-y
    >
      <template v-slot:activator="{ props }"> 
        <v-btn
        @click="checkbox_clicked"
        v-bind="props">
          <v-icon >mdi-dots-horizontal</v-icon>
        </v-btn>
        <!-- <input  type="checkbox"
         v-model="item['selected']" 
          v-bind="props"
          @click="checkbox_clicked" />  -->
       </template >
      <div>
        <v-btn 
          v-for="(btn, index) in btns"
          :key="index"
          @click="handleClick(btn.signal)"
        >
        <v-icon v-if="btn.icon">{{ btn.icon }}</v-icon>
         <span v-else>{{ btn.title }} </span>
      </v-btn>  
    </div>
    </v-menu> 
  </div>
</template>
<script>
  export default { 
    
    props:{
        header:Object,
        value:Object,
        refTables:Object,
        btns:Array
    },

    data(){
        
      return{
        item: this.getValue(),
      }
    },
      created(){
       this.$watch(
          'value',() => {
            this.item = this.getValue()
            },
          {deep: true}
    );
   
  },
  computed:{

  },
      methods: {
        handleClick(signal){
           this.$emit(signal,this.item)
        },
        getValue(){
          return this.value
        },
        checkbox_clicked(){
          this.$emit('record_selected',this.item)
        },
       
        open () {

        },
        close () {
          console.log('Dialog closed')
        },
        
        }
  }
</script>
    