<template>
  <div class="about">
    <h1>{{ project }}</h1>
    These pages are for viewing, proofing, editing and entering data related to the {{ project }}. 
    Each tab corresponds to data collected using either the Fishlab or Fish Data android apps. 
    Login to get started. 
  </div>
</template>

<script>
  

  export default {
    name: 'HomePage',
    data(){
      return{
    project: 'Yukon Fisheries Project'}
    },
    components: {
      
    },
  }
</script>
