<template>

  <v-container>
  <v-row>
      <v-col cols="12">
        <SiteVisit :record ="currentSiteVisit" v-on="handler"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8" >
          <BubbleGraph  :plotData="plotInfo" divId="plot" 
          @afterplot="plotChanged" v-on:fishSelected="fishSelected"></BubbleGraph>
      </v-col>
     </v-row>
     <v-tabs 
     v-if="currentSiteVisit.global_id !== '' && currentSiteVisit.global_id !== undefined"
v-model="tab"
  align-tabs="center"
  color="blue">
  <v-tab value="lab_fish">Fish</v-tab>
  <v-tab value="lab_fish_sample">Samples</v-tab>
</v-tabs>
<v-window v-model="tab"
v-if="currentSiteVisit.global_id !== '' && currentSiteVisit.global_id !== undefined">
  <v-window-item v-for="page in pages"
      :key="page"
      :value="page">
        <EditableTable 
        :value="tabTable(page)" 
        :fields="tableFields(page)" 
        :showAdd="showAdd" 
        sortField="created_ts"
        v-on="handler"
        :tableName="page"
        :addFields="tableFields(page)" 
        ></EditableTable>
    </v-window-item>
   
    </v-window>

 <template>
  <div>
     
    
  </div>
 </template>
 
  </v-container>
</template>

<script>

import EditableTable from '../components/EditableTable.vue'
import BubbleGraph from '../components/BubbleGraph.vue'
import SiteVisit from '../components/SiteVisit.vue'
import support from '../plugins/support'
import data from '../plugins/data'


export default {
  name: "FishLab",
  components:{EditableTable, BubbleGraph, SiteVisit},
   
  data() {
    return {
      pages:["lab_fish","lab_fish_sample"],
      selectedCell: null,
      selectedPoint: null,
      fishFilter: "",      
     showAdd:true,
      massArray: [],
      lengthArray: [],
      keyArray:[],
      tab:null,

       handler: {
          // onSiteVisitSet: this.siteVisitReset,
          submitSiteVisit: this.submitSiteVisit,
          add_record: this.addTableItem,
          submitItem: this.saveTableItem,
          delete: this.delete
      },
    }    
  },
  computed: {
    plotInfo() {
      return {
        datasets: [
        {
          borderColor: 'red',
          showLine: true,
          pointRadius: 0,
          label: 'Ideal Model',
          backgroundColor: 'red',
          data: [
            {
              x: 1,
              y: 1,
            },
            {
              x: 1000,
              y: 1000,
            }
          ]
        },
        {
          showLine: false,
          pointRadius: 5,
          label: 'Data Model',
          backgroundColor: 'blue',
          keys: this.pointkeys,
          data: this.fishPlotData
        },
        ],
      }
    },
    pointkeys() {
      return this.fish.map((el)=> el.species);
    },
    fishPlotData() {
      return this.fish.map((el) => ({x:el.mass_g, y:el.length}));
    },
    currentSiteVisit() {
          return this.$store.getters['getCurrentRecord']('site_visit');
        },
    //fishFilter: "lab_fish/visit_date/" + this.visit_date + "/station/" + this.station
    currentLabFish() {
      return this.$store.getters['getCurrentRecord']('lab_fish')
    },
    fish() {
      return this.$store.getters['tableData']('lab_fish');
    },
   fishFields(){
    return data.tableFields['lab_fish'];
   },
   lab_fish_sample() {
      return this.$store.getters['tableData']('lab_fish_sample').filter((el)=>
      this.fish.map((f) => f.sin).includes(el.sin ));
    },
   lab_fish_sample_fields(){
    return data.tableFields['lab_fish_sample'];
   },
  },
   created(){
    // this.$watch(
    //       'dataholder',() => {this.checkChanged()
    //       },
    //       {deep: true}
    // );
  },
  async mounted() {

    //fill plot data
     support.getApiData('dataTables','lab_fish_sample')
    // this.$watch(
    //       'fish',() => {this.refreshPlot()
    //       },
    //       {deep: true}
    // );
    
  },
  methods: {
    tableFields(tab){
      if (tab == 'lab_fish'){
        return this.fishFields
      }else{
        return this.lab_fish_sample_fields
      }
    },
    tabTable(tab){
      if (tab === 'lab_fish'){
        return this.fish
      }
        return this.lab_fish_sample
    },
    plotChanged() {
      this.status = 'Plot last updated at ' + new Date();
    },
   
   
    filterFish(station,visit_date){
      //getFishData()
      if ((visit_date !== undefined) && (station !==undefined)) {
      let endpoint ="lab_fish/visit_date/"+ visit_date +"/station/" + station + "/";
      console.log(endpoint);
      //this.getdataholder(endpoint,'labFish');
      this.fishFilter = endpoint //table component responds to change in endpoint
      }
    },
   

      addTableItem(info){
        let sample = {};
        if(info.tableName === 'lab_fish'){
          info.record.parent_id = this.$store.getters['getCurrentRecord']('site_visit')['global_id']
          info.record.station_name = this.$store.getters['getCurrentRecord']('site_visit')['station']
          info.record.visit_date= this.$store.getters['getCurrentRecord']('site_visit')['visit_date']
          if ((info.record.sin !== '')&& (info.record.sin !== undefined)) {
            if (!this.lab_fish_sample.map((s)=> s.sin).includes(info.record.sin)) {
              sample = {sin:info.record.sin}
            }
          }
        }
        support.submit(support.forceFieldTypes(info.tableName,[support.addMandatory(info.tableName,info.record)]), info.tableName)
        .then((result) =>{
          console.log(result)
          support.getApiData('dataTables',info.tableName,`${info.tableName}/visit_date/${this.$store.getters['attribute']('visit_date')}/station/${this.$store.getters['attribute']('station')}`)
          }
        )
        if (sample.sin !== undefined){
          support.submit(support.forceFieldTypes('lab_fish_sample',[support.addMandatory('lab_fish_sample',sample)]), 'lab_fish_sample')
          .then(() => {
            support.getApiData('dataTables','lab_fish_sample')
          })
        }

  },
    saveTableItem(info){
      let sample = {};
      if (info.tableName === 'lab_fish') {
        if ((info.record.sin !== '')&& (info.record.sin !== undefined)) {
              if (!this.lab_fish_sample.map((s)=> s.sin).includes(info.record.sin)) {
                sample = {sin:info.record.sin}
              }
            }
        
         support.submit(support.forceFieldTypes(info.tableName,[support.addMandatory(info.tableName,info.record)]), info.tableName)
          .then((result) =>{
            console.log(result)
            support.getApiData('dataTables',info.tableName,`${info.tableName}/visit_date/${this.$store.getters['attribute']('visit_date')}/station/${this.$store.getters['attribute']('station')}`)
            if (sample.sin !== undefined){ //after fish submitted submit the sample 
            support.submit(support.forceFieldTypes('lab_fish_sample',[support.addMandatory('lab_fish_sample',sample)]), 'lab_fish_sample')
            .then(() => {
              support.getApiData('dataTables','lab_fish_sample')
            })
          }  
        }
        );
      } else if (info.tableName === 'lab_fish_sample') {
        support.submit(support.forceFieldTypes(info.tableName,[support.addMandatory(info.tableName,info.record)]), info.tableName)
          .then((result) =>{
            console.log(result)
            support.getApiData('dataTables','lab_fish_sample')
          });
      }

  },
  delete(tableInfo){
    this.$store.commit("removeItem",{tableType:'dataTables',tableName:tableInfo.table, record: tableInfo.record, key:"global_id"})
    tableInfo.key = 'global_id';
    tableInfo.payload = [tableInfo.record];
    support.deleteRecord(tableInfo);
  },

  
    submitLabFish(tableItem){
      let payload = support.forceFieldTypes('lab_fish',[support.addMandatory('lab_fish',tableItem)])
      support.submit(payload,'lab_fish');
    } ,
    async submitSiteVisit(submitInfo){
      await this.submit(submitInfo)
      .then(()=>
        { console.log("success submitting data, reloading visit")
        this.$store.commit('setSiteVisit')
        })
      .catch(
        console.log("error submitting data")
        )

    },
    //fish selected on plot
    fishSelected(fishPosition){
      console.log(fishPosition)
      this.$store.commit('updateAttribute',{attribute:'current_lab_fish',value:this.fish[fishPosition]})
      //set current fish to selected dot
      //set current fish to selected=true

    },
    // refreshTable(){
    //   this.filterFish(this.station, this.visit_date);
    // },
    
   
}
    
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 .dataentry {
    display: flex;
    overflow-x: auto;
} 
footer {
  font-size: 70%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>