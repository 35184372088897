import { createStore } from 'vuex'
import support from '@/plugins/support'
import packageJson from "../../package.json";
import Cookies from "js-cookie";
import axios from "axios";
import qs from "qs";

axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT_URL;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
axios.defaults.withCredentials = true;

const store = createStore({
  state: {
    submitMessage:"",
    accessLevel: 0,
    username:'',
    version: packageJson.version,
    loggedIn: false,
    dataTables:{},
    refTables:{},
    views:{},
    currentRecords:{},
    visit_date:'',
    station:'',
    set_num:-1,
    tributary:'',
    current_catch:{},
    current_tally:{},
    current_net_set:{},
    current_site_visit:{},
  },
 // getters
 getters:{  

  dataCache: (state) => (tableName) => state.dataCache[tableName],
  tableData: (state) => (tableName) => state.dataTables[tableName],
  refTable: (state) => (tableName) => state.refTables[tableName],
  viewData: (state) => (tableName) => state.views[tableName],
  getCurrentRecord: (state) => (tableName) => state[`current_${tableName}`] ? state[`current_${tableName}`] : support.newRecord(tableName),
  refTables: (state) => state.refTables,
  dataTables: (state) => state.dataTables,
  appName: (state) => state.appName,
  attribute: (state) => (varname) => state[varname],
  authStatus: (state) => state.status,
  isLoggedIn: (state) => state.loggedIn,
  accessLevel: (state) => state.accessLevel,

  },
mutations:{
  updateAttribute(state, payload){
    state[payload.attribute] = payload.value
    if (payload.attribute === 'current_site_visit') {
      this.commit("setSiteVisitRecord")
      }
    if (payload.attribute === 'set_num') {
      this.commit("setSiteVisitRecord")
      this.commit("setNetSet")
    }
      //if the final selector is getting set look up the visit record
    if (((payload.attribute === 'visit_date') && 
    (state.station !== '')) || ((payload.attribute === 'station') && 
    (state.visit_date !== ''))){
      support.getApiData('dataTables','site_visit', `site_visit/visit_date/${state.visit_date}/station/${state.station}`)
      .then((result) => {
        this.commit('updateAttribute',{attribute:'current_site_visit', value:result.length > 0 ? result[0] : support.newRecord('site_visit')
      })})
    }
  },

  setNetSet(state) {

     state.current_net_set = 
      state.dataTables.net_set.filter((el) =>
        el.set_num === parseInt(state.set_num)).length > 0 ? state.dataTables.net_set.filter((el) =>
      el.set_num === parseInt(state.set_num))[0] : support.newRecord('net_set')
    
//catch and tally records
      state.current_catch = support.newRecord('catch')
      let endpoint = `catch/visit_date/${state.visit_date}/station/${state.station}/set_num/${state.set_num}`
      support.getApiData('dataTables','catch',endpoint).then((result) => {

        state.dataTables.catch = result
      })

      state.current_tally = support.newRecord('tally')
      endpoint = `tally/visit_date/${state.visit_date}/station/${state.station}/set_num/${state.set_num}`
     
      support.getApiData('dataTables','tally',endpoint).then((result) => {

        state.dataTables.tally= result
      })
    
    },
  

  setSiteVisitRecord(state){
    let endpoint = `net_set/visit_date/${state.visit_date}/station/${state.station}/`
     
    support.getApiData('dataTables','net_set', endpoint)
    .then((result) => {
      let records = result.filter((el) => 
      el.station === state.current_site_visit.station &
      el.visit_date === state.current_site_visit.visit_date
      )
      state.dataTables.net_set = records
      this.commit('setNetSet')
  })
  endpoint = `lab_fish/visit_date/${state.visit_date}/station/${state.station}/`
     
    support.getApiData('dataTables','lab_fish', endpoint)

  },


  
  refreshRecords(state, payload) {
    if (payload.tableType != null) {
      state[payload.tableType][payload.tableName] = payload.records;
    } else {
      state[payload.tableName] = payload.records;

    }
   
  },
  removeItem(state, deleteInfo){
    let foundItem, index = support.findItem(deleteInfo.record, state[deleteInfo.tableType][deleteInfo.tableName],[deleteInfo.key]);
    if (index >= 0){
      foundItem;
      state[deleteInfo.tableType][deleteInfo.tableName].splice(index, 1);
    }
   
  },
  resetPrimary(state) {
    state.current_site_visit = support.newRecord('site_visit')
    state.station=''
    state.tributary=''
    state.visit_date=''
    state.set_num = 0
  },
  auth_request(state) {
    state.status = "loading";
    state.loggedIn = false;
  },
  auth_success(state, userinfo) {
    state.status = "success";
    state.loggedIn = true;
    state.username = userinfo.user;
    state.accessLevel = userinfo.level;
    Cookies.set('user',userinfo.user, { expires: 31 })
    Cookies.set('accessLevel', userinfo.level,  { expires: 31 })
  },
  auth_error(state) {
    state.status = "error";
    state.loggedIn = false;
    Cookies.remove("auth");
    Cookies.remove('user')
    Cookies.remove('accessLevel')
  },
  logout(state) {
    state.status = "";
    state.loggedIn = false;
    state.username = "";
    state.accessLevel = 0;
    Cookies.remove("auth");
    Cookies.remove('user')
    Cookies.remove('accessLevel')
    
  },
  loggedIn(state,value) {
    state.loggedIn = value;
  },
},
  actions: {
    async login({ commit }, user) {
      
      try {
        commit("auth_request");
          let response = await axios.post(
          `/login/`,
          qs.stringify(user)
        );
        if (response.status === 200) {
          let userResponse = await axios.get(`/users/${user.username}`)
          if (userResponse.status === 200){
            let userAccess = userResponse.data[0]['access_level']
            let access_level = 0;
            if (userAccess === 'user') {
              access_level = 2;
            } else if (userAccess === 'viewer') {
              access_level = 1;
            }
            commit("auth_success", {user: user.username, level:access_level});
          } else {
            commit("auth_error")
          }
        } else {
          commit("auth_error");
        }
      } catch (err) {
        console.log(`err: ${err.message}`);
          commit("auth_error");
      }
    },
    async logout({ commit }) {

        await axios.post(`/logout/`)
        .then((response) => {
          if (response.status == 200) {
            commit("logout");
          } else {
            console.log("logout error!");
            commit("logout");
          }
        }).catch((err) => {
          console.log("logout error " + err);
          commit("logout");
        });

    },
    async checkAuthentication({ commit }) {
      if (Cookies.get("auth")) {
       
        let user = Cookies.get("user")
        let accessLevel = Cookies.get("accessLevel")
        if ((user !== null) && (accessLevel != null)) {
          commit("auth_success", {user: user, level:accessLevel});
        }
      }
    },
  },
  modules: {
  }
})

export default store;
