<template>
  <div class="siteset" >
    <div>
      <v-container class="mx-auto">
       Set Data
        <v-row>
         <v-col 
              sm="2"
              md="2"
             >  
          <label for="set_num">Select Set Number: </label>
          </v-col>
          <v-col
           sm = "10"
           md="2">
           <v-select 
            v-model="set_num"
            :items="onethroughfour"
            label="Set Number"
            dense
          />
          </v-col>
        </v-row>
        <v-row>
         
         <v-col
        cols="11"
        sm="5"
      >
        <Datepicker
        v-model="currentRecord.start_time" 
        format="yyyy-MM-dd hh:mm"
        :enable-time-picker="true"
        placeholder="start time"
        type="datetime"
        :default-value="visit_date"
        required
        clearable
        :disabled="!enabled" 
        @keyup.enter="submit"/>
        </v-col>
          <v-col
          cols="11"
          sm="5">
        <Datepicker
        v-model="currentRecord.stop_time" 
        format="yyyy-MM-dd hh:mm"
        :enable-time-picker="true"
        placeholder="stop time"
        type="datetime"
        :default-value="visit_date"
        required
        clearable
        :disabled="!enabled" 
        @keyup.enter="submit"/>
          </v-col>
        </v-row>

        <v-row>
             <v-col 
              sm = "10"
              md="2">              
              <v-text-field
              v-bind:disabled="!enabled"
              v-model="currentRecord.start_latitude"
              type="number" 
              label="Start Latitude" 
              @blur="submit"/>   
                   
            </v-col>
         
          <v-col sm = "10"
              md="2">
              <v-text-field
              v-bind:disabled="!enabled"
                v-model="currentRecord.start_longitude" 
                type="number" 
                label="Start Longitude"
                @blur="submit" />
                     
            </v-col>


             <v-col 
              sm = "10"
              md="2">              
              <v-text-field 
              v-bind:disabled="!enabled"
                v-model="currentRecord.stop_latitude"
                type="number" 
                label="Stop Latitude" 
                @blur="submit"/>   
                   
            </v-col>
         
          <v-col sm = "10"
              md="2">
              <v-text-field
              v-bind:disabled="!enabled"
                v-model="currentRecord.stop_longitude" 
                type="number" 
                label="Stop Longitude" 
                @blur="submit"/>
                     
            </v-col>
        </v-row>
        <v-row>
         
             <v-col 
              sm = "10"
              md="2">              
              <v-text-field 
              v-bind:disabled="!enabled"
                v-model="currentRecord.depth_start" 
                type="number" 
                label="Start Depth (m)"
                @blur="submit"/>   
                   
            </v-col>
         
          <v-col sm = "10"
              md="2">
              <v-text-field
              v-bind:disabled="!enabled"
                v-model="currentRecord.flow_start" 
                type="number" 
                label="Start Flow (m/s)" 
                @blur="submit"/>
                     
            </v-col>

             <v-col 
              sm = "10"
              md="2">              
              <v-text-field 
              v-bind:disabled="!enabled"
                v-model="currentRecord.depth_stop"
                type="number" 
                label="End Depth (m)" 
                @blur="submit"/>   
                   
            </v-col>
         
          <v-col sm = "10"
              md="2">
              <v-text-field
              v-bind:disabled="!enabled"
                v-model="currentRecord.flow_stop"
                type="number" 
                label="End Flow (m/s)" 
                @blur="submit" />
                     
            </v-col>
      </v-row>
      <v-row>
             
          <v-col sm = "10"
             md="10" >
              <v-text-field
              v-bind:disabled="!enabled"
                v-model="currentRecord.comment"
                label="Comment" 
                @blur="submit"/>       
            </v-col>
        </v-row> 
        <v-row>
          
          <v-col sm="1" md="2">
          <v-btn large color="primary" elevation="2" v-on:click="submit">
          Submit
          </v-btn>
          </v-col>
        </v-row>
      </v-container>
  </div>
  </div>
</template>

<script>

import support from '../plugins/support'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
export default {
  name: 'SiteSet',
  components:{Datepicker},
  
  props:{
    record: Object
  },
  created(){
   
  },
  async mounted(){
   
    this.$watch(
          'record',() => {
            this.currentRecord = this.record
          },
          {deep: true}
    );
    this.$watch(
      "set_num",
      () => {
          
        this.$store.commit("setNetSet")
      },
      { deep: true}
      );
  },

  data(){
    //var StartTimeLabel = "Start Time";
    return{
         
      setNum:this.record.set_num,
      onethroughfour:[1,2,3,4],
      currentRecord:this.record,
      date:this.sitedate,
        };
        
      },
      computed:{
        starttime:{
          get() {return typeof(this.currentRecord.start_time) === 'string' ? new Date(this.currentRecord.start_time) : this.currentRecord.start_time},
          set(value){this.currentRecord.start_time = value}
        },
        stoptime:{
          get() {return typeof(this.currentRecord.stop_time) === 'string' ? new Date(this.currentRecord.stop_time) : this.currentRecord.stop_time},
          set(value){this.currentRecord.stop_time = value}
        },
        enabled() {
        return this.set_num > 0
      },
        set_num:{
          get(){return this.$store.getters['attribute']('set_num')},
          set(value){
            this.$store.commit('updateAttribute',{attribute:'set_num', value:value})
        },
    
      },
      visit_date() {
          return this.$store.getters['attribute']('visit_date')
        },
    },
  methods:{
     
  
  
  updateSet(){
    console.log("setting set in set form")
    
    this.start_time_only = this.parseTime(this.record.start_time)
    this.end_time_only = this.parseTime(this.record.stop_time)
    this.currentRecord = this.record;
  },

  getSampleDateTime(newtime){
    let date = new Date(); 
   
    var timeSplit = newtime.split(':');
    if (timeSplit.length > 2){
    date.setHours(timeSplit[0],timeSplit[1],timeSplit[2].split(" ")[0]);
    }else if (timeSplit.length > 1){
      date.setHours(timeSplit[0],timeSplit[1])
    }
   
     return date;
 

  },



  submit: function() {
      //set the auto fields
     
      let  endpoint = "net_set/keys/p/";
      this.$emit("submitSet",{endpoint:endpoint,payload:support.forceFieldTypes('net_set',[support.addMandatory('net_set',this.currentRecord)]),tableName:'net_set'})
     
   },
  
   }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

</style>
