<template>
  <div>
    <v-col class="pa=3 ma=5">
      <v-row no-gutters justify=end>
    <v-btn large color="primary" elevation="2" v-on:click="downloadResults">Download</v-btn>
  </v-row>
  </v-col>
    <v-form class="text-right">
      <v-row class="ma=3 pa=3">
      <v-select
          v-model="filters.year.value"
          :items="recordYears"
          filled
          label="Year:"
          clearable
          multiple
        ></v-select>
        <v-select
        v-model="filters.species.value"
          :items="recordSpecies"
          item-value="species"
          filled
          label="Species:"
          clearable
          multiple
        ></v-select>
        <v-select
          v-model="filters.station.value"
          :items="recordStation"
          item-value="station"
          filled
          label="Station:"
          clearable
          multiple
        ></v-select>
        <v-select
          v-model="filters.visit_date.value"
          :items="recordVisitDate"
          item-value="visit_date"
          filled
          label="Vist Date:"
          clearable
          multiple
        ></v-select>
      </v-row>
      <v-container>
      <v-row> Active Filters: 
        <div >
        <v-chip-group
          column >
          <div v-for ="key in Object.keys(filters)"
            :key="key">
            <v-chip
              v-for = "value in filters[key].value"
              :key="value"
              class="ma-2"
              closable
              @click:close ="removeFilter(key,value)"
            >
              {{ value }}
            </v-chip>
          </div>
        </v-chip-group>
        </div>
      </v-row>
    </v-container>
      <v-container class="text-center">
        
      <v-row> Hidden Columns:
        <div class="ma=6">
        <v-chip-group
          column > 
            <v-chip
              v-for = "value in filteredKeys"
              :key="value"
              class="ma-2"
              closable
              @click:close ="addColumn(value)"
            >
              {{ value }}
            </v-chip>
          </v-chip-group>
          </div>
        </v-row>
      </v-container>
  </v-form>
   <v-data-table 
   :headers="headers"
   :items="items"
   density="compact"
   :items-per-page="itemsPerPage"
   >
   <template v-slot:headers="{ columns, isSorted, getSortIcon, toggleSort }">
      <tr>
        <template v-for="column in columns" :key="column.key">
          <td >
            <span class="mr-2 cursor-pointer" @click="() => toggleSort(column)">{{ column.title }}</span>
            <template v-if="isSorted(column)">
              <v-icon :icon="getSortIcon(column)"></v-icon>
            </template>
            <v-icon icon="$close" @click="() => removeColumn(column.key)"></v-icon>
          </td>
        </template>
      </tr>
    </template>
  </v-data-table>
  </div>
</template>

<script>
  
  import data from "../plugins/data"
  export default {
    name: 'SummaryData',

    data(){
      return{
        selected:"",
        fields:[],
        filteredKeys:["gear","net"],
        itemsPerPage:100,
        filters: {year:{value:[(new Date()).getFullYear()],field:"year"},
         species:{value:[],field:"field_species"},
         station:{value:[],field:"station"},
         visit_date:{value:[],field:"visit_date"},
      }
      }
      },
    components: {
      
    },
    mounted() {
      this.fields = data.tableFields["sample_query"]
    },
    computed: {
      records() {
        return this.$store.getters.viewData("sample_query")
      },
      items () {
        return this.records.filter((item) => this.itemPasses(item))
      },
      recordYears() {
        return Array.from(new Set(this.records.map((item) => (new Date(item.visit_date)).getFullYear())));
      },
      recordSpecies() {
        return Array.from(new Set(this.records.map((item) => (item.field_species))));
      },
      recordStation() {
        return Array.from(new Set(this.records.map((item) => (item.station))));
      },
      recordVisitDate() {
        return Array.from(new Set(this.records.map((item) => (new Date(item.visit_date).toDateString()))));
      },
      headers() {
        return this.fields.filter((header) => !this.filteredKeys.includes(header.key))
      }

    },
    methods: {
      itemPasses (item) {
        let arr = Object.values(this.filters).map((fil) => {
          if(fil.field === "year") {
            return Object.values(fil.value).includes((new Date(item.visit_date)).getFullYear()) || fil.value.length <= 0;
          } if(fil.field === "visit_date") {
            return Object.values(fil.value).includes((new Date(item.visit_date)).toDateString()) || fil.value.length <= 0;
          }else {
            return Object.values(fil.value).includes(item[fil.field]) || fil.value.length <=  0;
          }
       });

        return arr.every(v => v === true)
      //   (Object.values(this.filters.year.value).includes((new Date(item.visit_date)).getFullYear()) || this.filter.year.value.length <= 0) &&
      //   Object.values(this.filters).forEach((fil) => (Object.values(fil.value)).includes(item[fil.field]) || fil.value.length <=  0);
       },
      removeColumn (key) {
        this.filteredKeys.push(key)
        //this.headers = this.headers.filter(header => header.key !== key)
      },
      addColumn (key) {
        const index = this.filteredKeys.indexOf(key);
        if (index > -1) { // only splice array when item is found
          this.filteredKeys.splice(index, 1); // 2nd parameter means remove one item only
        }
      },
      removeFilter(key,value) {
        const index = this.filters[key].value.indexOf(value);
        if (index > -1) { // only splice array when item is found
          this.filters[key].value.splice(index, 1); // 2nd parameter means remove one item only
        }
      },
      downloadResults() {
      let csvData = this.$papa.unparse(
        this.items,{columns: this.headers.map((field) => field.key)}
      );
      const d = new Date();
      const ts = `${d.getUTCFullYear()}-${(d.getUTCMonth() + 1)
        .toString()
        .padStart(2, "0")}-${d.getUTCDate().toString().padStart(2, "0")}`;
      this.$papa.download(csvData, `ydfda_${ts}`);
    },

    
    }
  }
</script>
